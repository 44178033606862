import { CreatePodButton } from 'app/components/shared/FormDialog/CreatePod2';
import PodFAQ from 'app/components/shared/FormDialog/PodFAQ';
import CounsultantAccordionMenu from 'app/components/shared/PointsOfContactDropdown/PointOfContactAccordionMenu';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectSelectedDeptTab } from 'app/store/app/selectors';
import { setSelectedDeptTab } from 'app/store/app/slice';
import {
  selectDepartmentById,
  selectDepartments,
} from 'app/store/dept/selectors';
import { selectPodsBySubDeptID } from 'app/store/pods/selectors';
import { selectPointsOfContactByDeptId } from 'app/store/points_of_contact/selectors';
import { usePodRoles, usePointOfContactRoles } from 'app/store/roles/selectors';
import { Department } from 'app/types/Department';
import { allowCreateEditPod } from 'app/utils/hasPermissions/allowFeature';
import useUsers from 'app/utils/hooks/useUsers';
import {
  Button,
  List,
  ListItem,
  LoadingIndicator,
  Row,
  Switch,
  TabItem,
  TabPanel,
  Tabs,
  Typography,
} from 'cfa-react-components';
import { useEffect, useMemo, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Navigation from '../../components/Navigation/Navigation';
import PodCard from '../../components/shared/PodCard/PodCard';
import './PodStyling.scss';

export interface Employee {
  ID?: string;
  IsContingent: boolean | string;
  IsConsultant: boolean | string;
  Name: string;
  Pod: string;
  Role: string;
  Subdept: string;
}

export interface OldRole {
  ID: string;
  Role: string;
}

export interface Subdepartment {
  ID: string;
  SubdeptLong: string; //SubdeptLong
  SubdeptShort: string; //SubdeptShort
  pods: Podv1[];
  employees: Employee[];
}

export interface Podv1 {
  ID?: string;
  Info?: string;
  Name: string;
  members: Employee[];
  subDebt: Subdepartment;
}

export type PodsResponse<T> = {
  Items: T[];
};

const mapSubdeptNew = (list: Department[]) =>
  list.reduce((map: Map<string, Department>, subDept: Department) => {
    map.set(subDept.id, subDept);
    return map;
  }, new Map<string, Department>());

export const Pods = () => {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const selectedDeptTab = useAppSelector(selectSelectedDeptTab);
  const currentDept = useAppSelector(selectDepartmentById(selectedDeptTab));
  const newRoleList = usePodRoles();
  const pointOfContactRoleList = usePointOfContactRoles();
  const [complete, setComplete] = useState(false);
  const { isLoading } = useUsers();
  const [collapseAll, setCollapseAll] = useState(false);
  const [staffOnly, setStaffOnly] = useState(false);
  const allowPodCreate: boolean = allowCreateEditPod();

  const pointOfContactList = useAppSelector(
    selectPointsOfContactByDeptId(selectedDeptTab),
  );

  const deptPods = useAppSelector(selectPodsBySubDeptID(selectedDeptTab));
  useEffect(() => {
    dispatch(setSelectedDeptTab(selectedDeptTab));
    setComplete(true);
  }, [selectedDeptTab]);

  const newSubDeptMap = useMemo(() => {
    const result = mapSubdeptNew(departments);
    return result;
  }, [departments]);
  // Similary as the above currSubdept const, this message chanages based on the current subdept. It has been initialized to the first department alphabetically,
  // CTS. In the future, it would be initialized to the first index of the subdeptList array to be more dynamic and be prepared for any reord potentially.
  // const [message, setMessage] = useState("");

  const newHandleSecondarySelect = (e: string) => {
    // setNewCurrDept(e);
    dispatch(setSelectedDeptTab(e));
    // podsMap = useUpdatePodsMap(newSubDeptMap.get(e)?.ID!);
    // setMessage(newSubDeptMap.get(e)?.DepartmentName as string);
  };

  return (
    <>
      <Navigation />
      <div className="main-container" data-cy="main-container">
        <Tabs
          activeItemKey={selectedDeptTab}
          onChange={newHandleSecondarySelect}
          centered
          style={{
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          {departments.map(dept => (
            <TabItem key={dept.id} itemKey={dept.id} data-cy="dept-tab">
              {dept.alias}{' '}
            </TabItem>
          ))}
        </Tabs>
        {complete ? (
          <>
            {departments.map(dept => {
              return (
                <TabPanel
                  key={dept.id}
                  itemKey={dept.id}
                  activeItemKey={selectedDeptTab}
                >
                  <>
                    <div
                      className="BannerAndButtons"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        marginInline: '50px',
                      }}
                    >
                      <Typography
                        variant="h2"
                        color="primary"
                        data-cy="subdept-name"
                        style={{
                          textAlign: 'center',
                          paddingBottom: '20px',
                          margin: '0 auto',
                          marginBottom: '5px',
                        }}
                      >
                        {newSubDeptMap.get(selectedDeptTab)?.name}
                      </Typography>
                      <div
                        className="collapsingButtons"
                        style={{
                          alignSelf: 'flex-start',
                          marginTop: '-30px',
                          marginBottom: '7px',
                        }}
                      >
                        <Button
                          data-cy="expand-pods"
                          color="primary"
                          onClick={() => {
                            setCollapseAll(false);
                          }}
                          size="sm"
                          variant="outlined"
                        >
                          Expand Pods
                        </Button>
                        <Button
                          data-cy="collapse-pods"
                          color="primary"
                          onClick={() => {
                            setCollapseAll(true);
                          }}
                          size="sm"
                          variant="outlined"
                          style={{
                            marginLeft: '10px',
                          }}
                        >
                          Collapse Pods
                        </Button>
                      </div>
                    </div>
                    {complete ? (
                      <>
                        {' '}
                        <div
                          className="FilterContainer"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '-30px',
                            paddingTop: '0px',
                          }}
                        >
                          <Switch
                            data-cy="toggle"
                            aria-label="Filter staff?"
                            label="Staff Only"
                            crossOrigin={undefined}
                            checked={staffOnly}
                            onChange={e => {
                              setStaffOnly(e.currentTarget.checked);
                            }}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            style={{
                              // alignSelf: "flex-end",
                              marginBottom: '7px',
                              paddingRight: '50px',
                            }}
                          />
                        </div>
                        <CounsultantAccordionMenu
                          key={selectedDeptTab}
                          roleList={pointOfContactRoleList}
                          subDeptMap={newSubDeptMap}
                          currentDept={selectedDeptTab}
                          pointOfContactList={pointOfContactList}
                        />
                      </>
                    ) : (
                      <> </>
                    )}
                    <List
                      data-cy="pods-list"
                      style={{
                        columnGap: '10px',
                        padding: '1px 0px 10px 45px',
                        textAlign: 'left',
                        marginBlockStart: '1rem',
                        counterReset: 'grid',
                        columns: '18rem',
                        gridAutoFlow: 'dense',
                      }}
                    >
                      {deptPods
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(pod => {
                          return (
                            <ListItem
                              key={pod.id}
                              style={{
                                maxWidth: '280px',
                              }}
                            >
                              <PodCard
                                key={pod.id}
                                staffOnly={staffOnly}
                                rolesList={newRoleList}
                                pod={pod}
                                currDept={currentDept as Department}
                                collapseAll={collapseAll}
                              />
                            </ListItem>
                          );
                        })}
                    </List>
                    <Footer />
                  </>
                </TabPanel>
              );
            })}
          </>
        ) : (
          <>
            <Row
              style={{
                height: 'auto',
                width: '100%',
                position: 'relative',
                top: '72px',
              }}
            >
              <div className="loading">
                <LoadingIndicator size="md" variant="page" />
                Loading Pods
              </div>
            </Row>
          </>
        )}
        <div className="create-pod-button">
          <CreatePodButton allowCreate={allowPodCreate} />
          <PodFAQ />
        </div>
      </div>
    </>
  );
};
